import {
  AWTLogManager,
  AWTEventPriority,
  AWTEventProperties,
  AWTPiiKind,
} from '@aria/webjs-sdk'

export { AWTPiiKind } from '@aria/webjs-sdk'

interface LogProperty {
  [name: string]: string | number | boolean
}

interface PiiProperty {
  [name: string]: {
    kind: AWTPiiKind
    value: string | number
  }
}

export function logEvent(
  name: string,
  properties: LogProperty,
  priority = AWTEventPriority.Normal
) {
  AWTLogManager.getLogger().logEvent({
    name,
    properties,
    priority,
  })
}

export function logEventWithPii(
  name: string,
  plainProperties: LogProperty,
  piiProperties: PiiProperty,
  priority = AWTEventPriority.Normal
) {
  const props = new AWTEventProperties(name)
  Object.keys(plainProperties).forEach((n) =>
    props.setProperty(n, plainProperties[n])
  )
  Object.keys(piiProperties).forEach((n) =>
    props.setPropertyWithPii(n, piiProperties[n].value, piiProperties[n].kind)
  )
  props.setEventPriority(priority)
  AWTLogManager.getLogger().logEvent(props)
}
