import React, { Suspense, useState } from 'react'
import { Route, Redirect, Switch, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useStore, connect, ConnectedProps } from 'react-redux'
import { AzureAD, AuthenticationState } from 'react-aad-msal'
import { Text, Link } from 'office-ui-fabric-react'
import { authProvider } from '@/authProvider'

import About from '@/About'
import { RootState } from '@/store'
import ApiActions from './store/api/actions'

const JoltUI = React.lazy(
  () => import(/*webpackChunkName: "outputs"*/ '@/components/joltui/JoltUI')
)

const JoltOutputs = React.lazy(
  () =>
    import(/*webpackChunkName: "outputs"*/ '@/components/joltui/JoltOutputs')
)

import JoltNotifications from '@/components/joltui/JoltNotifications'

const mapState = (state: RootState) => ({
  authInitialized: state.aad.initialized,
  authState: state.aad.state,
})

const mapDispatch = {
  loadNotices: ApiActions.loadNotices,
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const authPlaceholder = (initialized: boolean, state: AuthenticationState) => {
  if (!initialized) {
    return <Text variant="xxLarge">Authentication initializing.</Text>
  }
  switch (state) {
    case AuthenticationState.Authenticated:
      return null
    case AuthenticationState.Unauthenticated:
      return (
        <Text variant="xxLarge">
          Please <Link onClick={() => authProvider.login()}>sign in</Link>.
        </Text>
      )
    case AuthenticationState.InProgress:
      return <Text variant="xxLarge">Authentication in progress.</Text>
  }
}

const App: React.FunctionComponent<PropsFromRedux> = ({
  authInitialized,
  authState,
  loadNotices,
}) => {
  const [selectedCommand, setSelectedCommand] = useState('')
  const history = useHistory()
  loadNotices()
  setInterval(() => {
    if (authState === AuthenticationState.Authenticated) {
      loadNotices()
    }
  }, 15000)
  return (
    <>
      {authPlaceholder(authInitialized, authState)}
      <AzureAD
        provider={authProvider}
        reduxStore={useStore()}
        forceLogin={false}
      >
        <Switch>
          <Route path="/jolt/:command?">
            <Helmet>
              <title>
                Jolt UI{selectedCommand !== '' ? `- ${selectedCommand}` : ''}
              </title>
            </Helmet>
            <Suspense fallback={<></>}>
              <JoltUI
                onCommandSelected={(cmd) => {
                  history.replace(`/jolt/${cmd}`)
                  setSelectedCommand(cmd)
                }}
              />
            </Suspense>
          </Route>
          <Route path="/outputs">
            <Suspense fallback={<></>}>
              <Helmet>
                <title>Jolt UI Outputs</title>
              </Helmet>
              <JoltOutputs />
            </Suspense>
          </Route>
          <Route path="/notices">
            <Helmet>
              <title>Jolt UI Notices</title>
            </Helmet>
            <JoltNotifications />
          </Route>
          <Route path="/about">
            <Helmet>
              <title>About the Jolt UI</title>
            </Helmet>
            <About />
          </Route>
          <Redirect path="/" to="/jolt" />
        </Switch>
      </AzureAD>
    </>
  )
}

export default connector(App)
