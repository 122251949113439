import React from 'react'
import { useHistory } from 'react-router-dom'
import { Stack, Text, Link, getTheme } from 'office-ui-fabric-react'

const theme = getTheme()

const About: React.FunctionComponent = () => {
  const history = useHistory()
  return (
    <Stack>
      <Text
        variant="xxLarge"
        styles={{ root: { color: theme.palette.themePrimary } }}
      >
        About this tool
      </Text>
      <Text variant="large">
        You've arrived at Jolt. A few commands that normally require the command
        line can be executed here. The main section is under{' '}
        <Link to="/jolt" onClick={() => history.push('/jolt')}>
          Jolt UI
        </Link>
        . From there, you can select which Jolt command you'd like to run and
        provide the command line arguments it would normally require.
      </Text>
      <Text
        variant="xLarge"
        styles={{ root: { color: theme.palette.themeSecondary } }}
      >
        Current limitations
      </Text>
      <ul>
        <li>
          <Text variant="large">
            Presently, file uploads that are used on the command line are not
            supported. This is being worked on, however.
          </Text>
        </li>
        <li>
          <Text variant="large">
            Formatting of the output results in a pretty format is currently
            limited to 100 lines of output per command. The performance isn't
            quite good enough to pretty print more than that. Using the Download
            Results or Copy Results options would allow you to use{' '}
            <code>jq</code> to further process the data.
          </Text>
        </li>
      </ul>
      <Text
        variant="xLarge"
        styles={{ root: { color: theme.palette.themeSecondary } }}
      >
        Need help?
      </Text>
      <Text variant="large">
        This is a new tool wrapping around an old tool, so there could be some
        issues with input and output. If you have problems, contact Slepp.
      </Text>
    </Stack>
  )
}

export default About
