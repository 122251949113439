import {
  JoltExecuteQuery,
  JoltNoticeInterface,
  JoltOutputInterface,
  JoltCommandDefinitions,
} from '@/typings/api'
import { ApiAction, ApiActionTypes } from './types'
import api from '@/services/api'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

function commandsLoaded(commands: JoltCommandDefinitions) {
  return {
    type: ApiAction.COMMANDS_LOADED,
    commands,
  }
}

function loadCommandsError(message: string) {
  return {
    type: ApiAction.LOAD_COMMANDS_ERROR,
    message,
  }
}

export const loadCommands = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch({ type: ApiAction.LOAD_COMMANDS })
    dispatch(setBusy(true))
    try {
      dispatch(commandsLoaded(await api.loadCommands()))
    } catch (e) {
      let msg = e.toString()
      if (e.isAxiosError === true) {
        msg = e.response?.data?.message
        if (!msg) {
          msg = `${e.name}: ${e.message}`
        }
      }
      dispatch(loadCommandsError(msg))
    }
    dispatch(setBusy(false))
  }
}

export const loadNotices = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch({ type: ApiAction.LOAD_NOTICES })
    try {
      dispatch(setNotices(await api.loadNotices()))
    } catch (e) {
      let msg = e.toString()
      if (e.isAxiosError === true) {
        msg = e.response?.data?.message
        if (!msg) {
          msg = `${e.name}: ${e.message}`
        }
      }
      console.log(msg)
      //dispatch(loadCommandsError(msg))
    }
  }
}

export const executeCommand = ({
  command,
  commandLine,
  query,
}: JoltExecuteQuery): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch({
      type: ApiAction.EXECUTE_COMMAND,
      command,
      commandLine,
      query,
    })
    dispatch(setBusy(true))
    dispatch(
      addOutput(
        await api.executeCommand({
          command,
          commandLine,
          query,
        })
      )
    )
    dispatch(setBusy(false))
  }
}

function setBusy(busy: boolean): ApiActionTypes {
  return {
    type: ApiAction.SET_BUSY,
    busy,
  }
}

function addOutput(output: JoltOutputInterface): ApiActionTypes {
  return {
    type: ApiAction.ADD_OUTPUT,
    output,
  }
}

function removeOutput(operationId: number): ApiActionTypes {
  return {
    type: ApiAction.REMOVE_OUTPUT,
    operationId,
  }
}

function setNotices(notices: JoltNoticeInterface[]): ApiActionTypes {
  return {
    type: ApiAction.SET_NOTICES,
    notices,
  }
}

export default {
  loadCommands,
  executeCommand,
  loadNotices,
  setBusy,
  addOutput,
  removeOutput,
  setNotices,
}
