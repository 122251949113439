import { JoltUIState, JoltUIActionTypes, JoltUIAction } from './types'
import storage from '@/services/storage'

const defaultShownStorage = storage.local.getItem('joltui:shownargs')
let defaultShownArgs = {}

if (defaultShownStorage) {
  try {
    defaultShownArgs = JSON.parse(defaultShownStorage)
  } catch (e) {
    storage.local.removeItem('joltui:shownargs')
    defaultShownArgs = {}
  }
}

const initialState: JoltUIState = {
  selectedCommand: '',
  commandArguments: {},
  shownArgs: defaultShownArgs,
}

const joltui = (
  state = initialState,
  action: JoltUIActionTypes
): JoltUIState => {
  switch (action.type) {
    case JoltUIAction.SET_COMMAND_ARGUMENTS:
      return {
        ...state,
        commandArguments: {
          ...state.commandArguments,
          [action.command]: {
            ...state.commandArguments[action.command],
            args: action.args,
          },
        },
      }
    case JoltUIAction.SET_COMMAND_OPTIONS:
      return {
        ...state,
        commandArguments: {
          ...state.commandArguments,
          [action.command]: {
            ...state.commandArguments[action.command],
            opts: action.args,
          },
        },
      }
    case JoltUIAction.SET_SELECTED_COMMAND:
      return {
        ...state,
        selectedCommand: action.command,
      }
    case JoltUIAction.SET_SHOWN_ARGS:
      const shownArgs = {
        ...state.shownArgs,
        [action.command]: action.shown,
      }
      storage.local.setItem('joltui:shownargs', JSON.stringify(shownArgs))
      return {
        ...state,
        shownArgs,
      }
    default:
      return state
  }
}

export default joltui
