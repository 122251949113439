import React from 'react'
import { withRouter, useRouteMatch } from 'react-router-dom'
import { Nav } from 'office-ui-fabric-react/lib/Nav'

const NavigationLinks = withRouter(({ history }) => {
  let selectedKey
  if (useRouteMatch('/jolt/:command?')) {
    selectedKey = 'joltui'
  }
  return (
    <Nav
      selectedKey={selectedKey}
      onLinkClick={(event, element) => {
        if (event && element) {
          event.preventDefault()
          history.push(element.url)
        }
      }}
      groups={[
        {
          links: [
            {
              name: 'Jolt UI',
              url: '/jolt',
              key: 'joltui',
            },
            {
              name: 'Outputs',
              url: '/outputs',
              key: 'outputs',
            },
            {
              name: 'Notices',
              url: '/notices',
              key: 'notices',
            },
            {
              name: 'About',
              url: '/about',
              key: 'about',
            },
          ],
        },
      ]}
    />
  )
})

const Navigation: React.FunctionComponent = () => {
  return <NavigationLinks />
}

export default Navigation
