import { Configuration, AuthenticationParameters } from 'msal'
import 'regenerator-runtime/runtime'
import {
  MsalAuthProvider,
  LoginType,
  IMsalAuthProviderConfig,
} from 'react-aad-msal'

// Msal Configurations
const config = {
  auth: {
    authority: `https://login.microsoftonline.com/${process.env.MSAL_tenantId}`,
    clientId: process.env.MSAL_clientId,
    redirectUri: `${window.location.origin}`,
    navigateToLoginRequestUrl: false,
    validateAuthority: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
} as Configuration

// Authentication Parameters
const authenticationParameters: AuthenticationParameters = {
  scopes: (process.env.MSAL_scopes as string).split(','),
}

// Options
const options: IMsalAuthProviderConfig = {
  loginType: LoginType.Popup,
  tokenRefreshUri: `${process.env.Redirect_URI}`,
}

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
)
