import { combineReducers, createStore, applyMiddleware } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import aad from '@/store/aad/reducers'
import api from '@/store/api/reducers'
import joltui from '@/store/joltui/reducers'
import ApiActions from '@/store/api/actions'

const rootReducer = combineReducers({
  aad,
  api,
  joltui,
})

export type RootState = ReturnType<typeof rootReducer>

const composeEnhancers = composeWithDevTools({
  name: 'Jolt',
  actionCreators: ApiActions,
  trace: true,
})

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<RootState>))
)

export default store
