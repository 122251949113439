import { ApiState, ApiActionTypes, ApiAction } from './types'

const initialState: ApiState = {
  busy: false,
  commands: {},
  commandsLoading: false,
  commandsErrorMessage: '',
  outputs: [],
  notices: [],
}

const api = (state = initialState, action: ApiActionTypes): ApiState => {
  switch (action.type) {
    case ApiAction.SET_BUSY:
      return {
        ...state,
        busy: action.busy,
      }
    case ApiAction.LOAD_COMMANDS:
      return {
        ...state,
        commandsLoading: true,
      }
    case ApiAction.COMMANDS_LOADED:
      return {
        ...state,
        commands: action.commands,
        commandsErrorMessage: '',
        commandsLoading: false,
      }
    case ApiAction.LOAD_COMMANDS_ERROR:
      return {
        ...state,
        commands: {},
        commandsLoading: false,
        commandsErrorMessage: action.message,
      }
    case ApiAction.EXECUTE_COMMAND:
      return state
    case ApiAction.REMOVE_OUTPUT:
      return {
        ...state,
        outputs: state.outputs.filter(
          (output) => output.operationId !== action.operationId
        ),
      }
    case ApiAction.ADD_OUTPUT:
      return {
        ...state,
        outputs: [action.output, ...state.outputs],
      }
    case ApiAction.SET_NOTICES:
      return {
        ...state,
        notices: action.notices,
      }
    default:
      return state
  }
}

export default api
