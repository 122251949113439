import React from 'react'
import { Stack, StackItem } from 'office-ui-fabric-react'
import { BrowserRouter as Router } from 'react-router-dom'

import Navigation from './Navigation'
import TopBar from './TopBar'

import styles from '@/styles.module.scss'

const tokens = {
  childrenGap: 10,
}

const Layout: React.FunctionComponent = ({ children }) => {
  return (
    <Router>
      <Stack tokens={tokens} className={styles.grid}>
        <StackItem className={styles.row}>
          <TopBar />
        </StackItem>
        <Stack horizontal tokens={tokens} className={styles.row}>
          <StackItem disableShrink className={styles.nav}>
            <Navigation />
          </StackItem>
          <StackItem grow className={styles.mainContent}>
            {children}
          </StackItem>
        </Stack>
      </Stack>
    </Router>
  )
}

export default Layout
