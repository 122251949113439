import {
  JoltOutputInterface,
  JoltNoticeInterface,
  JoltQuery,
  JoltCommandDefinitions,
} from '@/typings/api'

export type ApiState = {
  busy: boolean
  commands: JoltCommandDefinitions
  commandsLoading: boolean
  commandsErrorMessage: string
  outputs: JoltOutputInterface[]
  notices: JoltNoticeInterface[]
}

export enum ApiAction {
  SET_BUSY = 'SET_BUSY',
  LOAD_COMMANDS = 'LOAD_COMMANDS',
  COMMANDS_LOADED = 'COMMANDS_LOADED',
  LOAD_COMMANDS_ERROR = 'LOAD_COMMANDS_ERROR',
  EXECUTE_COMMAND = 'EXECUTE_COMMAND',
  ADD_OUTPUT = 'ADD_OUTPUT',
  REMOVE_OUTPUT = 'REMOVE_OUTPUT',
  LOAD_NOTICES = 'LOAD_NOTICES',
  SET_NOTICES = 'SET_NOTICES',
}

export interface SetBusyAction {
  type: typeof ApiAction.SET_BUSY
  busy: boolean
}

export interface LoadCommandsAction {
  type: typeof ApiAction.LOAD_COMMANDS
}

export interface LoadNoticesAction {
  type: typeof ApiAction.LOAD_NOTICES
}

export interface CommandsLoadedAction {
  type: typeof ApiAction.COMMANDS_LOADED
  commands: JoltCommandDefinitions
}

export interface CommandsLoadErrorAction {
  type: typeof ApiAction.LOAD_COMMANDS_ERROR
  message: string
}

export interface ExecuteCommandAction {
  type: typeof ApiAction.EXECUTE_COMMAND
  command: string
  commandLine: string
  query: JoltQuery
}

export interface AddOutputAction {
  type: typeof ApiAction.ADD_OUTPUT
  output: JoltOutputInterface
}

export interface RemoveOutputAction {
  type: typeof ApiAction.REMOVE_OUTPUT
  operationId: number
}

export interface SetNoticesAction {
  type: typeof ApiAction.SET_NOTICES
  notices: JoltNoticeInterface[]
}

export type ApiActionTypes =
  | SetBusyAction
  | LoadCommandsAction
  | CommandsLoadedAction
  | CommandsLoadErrorAction
  | ExecuteCommandAction
  | AddOutputAction
  | RemoveOutputAction
  | LoadNoticesAction
  | SetNoticesAction
