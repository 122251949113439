import {
  JoltQuery,
  JoltOutputInterface,
  JoltNoticeInterface,
  JoltCommandDefinitions,
} from '@/typings/api'
import Axios from 'axios'
import { authProvider } from '@/authProvider'
import { logEvent } from '@/services/aria'
import { AuthenticationParameters } from 'msal'

export interface ExecuteCommandOptions {
  command: string
  commandLine: string
  query: JoltQuery
}

const axios = Axios.create({
  baseURL: process.env.API_base,
})

const apiTokenOptions: AuthenticationParameters = {
  scopes: (process.env.MSAL_Jolt_scopes as string).split(','),
}

axios.interceptors.request.use(async (config) => {
  // TODO: See if this can be fixed for iOS Safari, but right now it is non-functional.
  try {
    const token = (await authProvider.getAccessToken(apiTokenOptions))
      .accessToken
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.common.Authorization = `Bearer ${token}`
    }
  } catch (e) {
    console.log('User is not yet authorized.')
    throw e
  }
  return config
})

const loadCommands = async (): Promise<JoltCommandDefinitions> => {
  try {
    const response = await axios.get('commands')
    return response.data
  } catch (e) {
    console.log(`Commands not loadable: ${e}`)
    throw e
  }
}

const loadNotices = async (): Promise<JoltNoticeInterface[]> => {
  try {
    const response = await axios.get('notices')
    return response.data
  } catch (e) {
    console.log(`Notices not retrieved: ${e}`)
    throw e
  }
}

const executeCommand = async ({
  command,
  commandLine,
  query,
}: ExecuteCommandOptions): Promise<JoltOutputInterface> => {
  const start = new Date().getTime()
  const when = new Date()
  let output = {
    query: { ...query },
    operationId: when.getTime(),
    when,
    command,
    commandLine,
  } as JoltOutputInterface

  try {
    const response = await axios.post(`execute/${command}`, query)
    output = {
      ...output,
      isError: false,
      output: response.data.output,
      extraOutput: response.data.extraOutput,
      rawOutput:
        response.data.output.join('\n') + response.data.extraOutput.join('\n'),
      format: response.data.format,
      code: response.data.code,
    }
    let duration = new Date().getTime() - start
    logEvent('execute_command', {
      success: true,
      code: response.data.code,
      duration,
      command,
    })

    const parseStart = new Date().getTime()
    output.rawOutput =
      output.output.join('\n') +
      (output.extraOutput.length > 0 ? '\n' : '') +
      output.extraOutput.join('\n') +
      '\n'

    output.output = response.data.output.map((line: string):
      | string
      | object => {
      if (line.substring(0, 1) === '{' || line.substring(0, 1) === '[') {
        try {
          return JSON.parse(line)
        } catch (e) {
          return line as string
        }
      }
      return line as string
    })

    duration = new Date().getTime() - parseStart

    logEvent('parse_command_output', {
      duration,
      lines: output.output.length,
    })
  } catch (e) {
    let message
    if (e.response !== undefined) {
      message = `${e.toString()}: Code: ${
        e.response?.data?.error?.code
      }, Message: ${e.response?.data?.error?.message}`
    } else {
      message = e.toString()
    }

    output = {
      ...output,
      isError: true,
      errorMessage: message,
    }
    const duration = new Date().getTime() - start
    logEvent('execute_command', {
      success: false,
      duration,
      command,
    })
  }
  return output
}

const loadPhoto = async (): Promise<string | undefined> => {
  try {
    const token = (await authProvider.getAccessToken({ scopes: ['User.Read'] }))
      .accessToken
    const response = await axios.get(
      'https://graph.microsoft.com/v1.0/me/photo/$value',
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return URL.createObjectURL(response.data)
  } catch (e) {
    console.log(`loadPhoto: ${e}`)
    return undefined
  }
}

export default {
  loadCommands,
  executeCommand,
  loadNotices,
  loadPhoto,
}
