import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '@/store'
import copy from 'copy-to-clipboard'

const mapState = (state: RootState) => ({
  notices: state.api.notices,
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const JoltNotifications: React.FunctionComponent<PropsFromRedux> = ({
  notices,
}) => {
  return (
    <div>
      {notices.map((notice) => {
        return (
          <div key={notice._id.$id}>
            <div><b>{notice.ts}</b></div>
            <div>{notice.message}</div>
            {notice.output &&
              <div>
                <code onClick={() => copy(notice.output)}>{notice.output}</code>
              </div>
            }
            {notice.url &&
              <div><a href={notice.url}>Link to file or more info</a></div>
            }
            <hr></hr>
          </div>
        )
      })}
    </div>
  )
}

export default connector(JoltNotifications)
