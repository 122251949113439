import { JoltQuery, JoltArguments } from '@/typings/api'

export type JoltUIState = {
  selectedCommand: string
  commandArguments: {
    [command: string]: JoltQuery
  }
  shownArgs: {
    [command: string]: {
      [arg: string]: boolean
    }
  }
}

export enum JoltUIAction {
  SET_SELECTED_COMMAND = 'SET_SELECTED_COMMAND',
  SET_COMMAND_ARGUMENTS = 'SET_COMMAND_ARGUMENTS',
  SET_COMMAND_OPTIONS = 'SET_COMMAND_OPTIONS',
  SET_SHOWN_ARGS = 'SET_SHOWN_ARGS',
}

export interface SetSelectedCommand {
  type: typeof JoltUIAction.SET_SELECTED_COMMAND
  command: string
}

export interface SetCommandArguments {
  type: typeof JoltUIAction.SET_COMMAND_ARGUMENTS
  command: string
  args: JoltArguments
}

export interface SetCommandOptions {
  type: typeof JoltUIAction.SET_COMMAND_OPTIONS
  command: string
  args: JoltArguments
}

export interface SetShownArgs {
  type: typeof JoltUIAction.SET_SHOWN_ARGS
  command: string
  shown: {
    [arg: string]: boolean
  }
}

export type JoltUIActionTypes =
  | SetSelectedCommand
  | SetCommandArguments
  | SetCommandOptions
  | SetShownArgs
