import React, { useState, useEffect } from 'react'
import {
  Stack,
  StackItem,
  Image,
  ImageFit,
  Text,
  getTheme,
  IContextualMenuProps,
  IContextualMenuItem,
  DefaultButton,
  loadTheme,
  Persona,
  PersonaSize,
  CommandBarButton,
} from 'office-ui-fabric-react'
import { AzureThemeLight, AzureThemeDark } from '@uifabric/azure-themes'

import Logo from '@/assets/sparks-head.svg'
import { RootState } from '@/store'
import { connect, ConnectedProps } from 'react-redux'
import { authProvider } from '@/authProvider'
import storage from '@/services/storage'
import api from '@/services/api'
import { AuthenticationState } from 'react-aad-msal'

const theme = getTheme()

const mapState = (state: RootState) => ({
  isAuthorized: state.aad.state === AuthenticationState.Authenticated,
  user: state.aad.account.name,
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const TopBar: React.FunctionComponent<PropsFromRedux> = ({
  isAuthorized,
  user,
}) => {
  const [themeChoice, setThemeChoice] = useState(
    storage.local.getItem('joltui:theme') ?? 'light'
  )
  const [photo, setPhoto] = useState<string>()

  useEffect(() => {
    storage.local.setItem('joltui:theme', themeChoice)
  }, [themeChoice])

  useEffect(() => {
    if (isAuthorized) {
      async function loadPhoto() {
        setPhoto(await api.loadPhoto())
      }
      loadPhoto()
    }
  }, [isAuthorized])

  let userBox = null
  if (user !== undefined && user !== '') {
    const menuItems: IContextualMenuItem[] = [
      {
        key: 'darkmode',
        text: themeChoice !== 'dark' ? 'Make it darker' : 'See the light mode',
        onClick: () => {
          const dark = !(themeChoice === 'dark') // they want the other one
          loadTheme({
            palette: dark ? AzureThemeDark.palette : AzureThemeLight.palette,
            semanticColors: dark
              ? AzureThemeDark.semanticColors
              : AzureThemeLight.semanticColors,
          })
          ;[
            document.getElementsByTagName('html')?.[0],
            document.getElementsByTagName('body')?.[0],
            document.getElementById('app'),
          ].forEach((e) => {
            if (e) {
              e.style.background = dark ? 'black' : 'white'
            }
          })

          setThemeChoice(dark ? 'dark' : 'light')
        },
      },
      {
        key: 'signout',
        text: 'Sign Out',
        onClick: () => authProvider.logout(),
      },
    ]
    const menuProps: IContextualMenuProps = {
      shouldFocusOnMount: false,
      items: menuItems,
    }
    userBox = (
      <CommandBarButton menuProps={menuProps}>
        <Persona
          size={PersonaSize.size32}
          imageShouldFadeIn
          imageUrl={photo}
          imageAlt={user}
          text={user}
        />
      </CommandBarButton>
    )
  }

  return (
    <Stack
      horizontal
      horizontalAlign="stretch"
      verticalAlign="center"
      styles={{ root: { margin: '1em' } }}
    >
      <StackItem grow>
        <Stack
          horizontal
          grow
          verticalAlign="center"
          tokens={{ childrenGap: 8 }}
        >
          <StackItem align="center">
            <Image
              src={Logo}
              width={32}
              height={32}
              imageFit={ImageFit.contain}
            />
          </StackItem>
          <StackItem>
            <Text variant="xxLarge">Jolt</Text>
          </StackItem>
        </Stack>
      </StackItem>
      <StackItem disableShrink>
        <Text variant="large">
          {userBox ?? (
            <DefaultButton
              text="Sign In"
              styles={{ root: { color: theme.palette.themeSecondary } }}
              onClick={() => {
                authProvider.login()
              }}
            />
          )}
        </Text>
      </StackItem>
    </Stack>
  )
}

export default connector(TopBar)
