import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'

import { mergeStyles, loadTheme, Fabric } from 'office-ui-fabric-react'

import { initializeIcons } from 'office-ui-fabric-react/lib/Icons'

import { AzureThemeLight, AzureThemeDark } from '@uifabric/azure-themes'

import { AWTLogManager } from '@aria/webjs-sdk'
import store from '@/store'
import App from '@/App'
import { Layout } from '@/components/layout'

import storage from '@/services/storage'

initializeIcons()

AWTLogManager.initialize(process.env.ARIA_ingestionKey as string)

const choice = storage.local.getItem('joltui:theme')
const dark = choice === 'dark'
loadTheme({
  palette: dark ? AzureThemeDark.palette : AzureThemeLight.palette,
  semanticColors: dark
    ? AzureThemeDark.semanticColors
    : AzureThemeLight.semanticColors,
})

// Inject some global styles
mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#app)': {
      //margin: 0,
      //padding: 0,
      height: '100vh',
      background: dark ? 'black' : 'white',
    },
  },
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const module: any

if (module.hot) {
  module.hot.accept()
}

ReactDOM.render(
  <Provider store={store}>
    <Fabric>
      <Layout>
        <App />
      </Layout>
    </Fabric>
  </Provider>,
  document.getElementById('app')
)
